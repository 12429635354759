import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

const BlogHeroSection = () => {
  const { t } = useTranslation('blog');
  return (
    <section className="section-1">
      <div className="container">
        <div className="section-1__content">
          <div className="section-1__block u-spacing-large">
            <h2 className="section-1__title u-title-primary">
              {t('Hero.title', 'Article')}
            </h2>
            <div className="section-1__text u-text-secondary u-color-lavender">
              {t(
                'Hero.text',
                `Follow the Flow blog for product announcements, feature updates,
              user stories, and technical posts`
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogHeroSection;
