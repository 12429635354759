import React, { useEffect, useState, Fragment } from 'react';
import Post from './Post';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StoryChiefDataQuery } from '../../../gatsby-graphql';
import slugify from 'slug';
import FlowPoster from './FlowPoster';

export interface PostData {
  id: string;
  language: string;
  title: string;
  excerpt: string;
  slug: string;
  featured_image: {
    regular?: string;
    full?: string;
    large?: string;
  };
  categories: Array<{
    name: string;
  }>;
  author: {
    name: string;
    profile_picture: string;
  };
}

interface PostsProps {
  categoryFilter?: 'News' | 'Money methods' | 'Business' | 'Stories';
}

const Posts = ({ categoryFilter }: PostsProps) => {
  const { i18n } = useTranslation();
  const [postData, setPostData] = useState<PostData[]>([]);
  const data: StoryChiefDataQuery = useStaticQuery(graphql`
    query StoryChiefData {
      allStorychief(sort: { fields: [published_at], order: DESC }) {
        edges {
          node {
            excerpt
            id
            language
            slug
            title
            featured_image {
              data {
                sizes {
                  regular
                }
              }
            }
            categories {
              data {
                name
              }
            }
            author {
              data {
                first_name
                last_name
                profile_picture {
                  data {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  // Array of all news articles
  // const allNews = new Array(160).fill(1);

  // State for the list
  // const [list, setList] = useState([...allNews.slice(0, 10)]);

  // State to trigger oad more
  // const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  // const [hasMore, setHasMore] = useState(allNews.length > 10);

  // Set a ref for the loading div
  // const loadRef = useRef();

  // Handle intersection with load more div
  // const handleObserver = (entities: any) => {
  //   const target = entities[0];
  //   if (target.isIntersecting) {
  //     setLoadMore(true);
  //   }
  // };

  useEffect(() => {
    const languageSpecificData = data.allStorychief.edges
      .map((post) => {
        return {
          id: post.node.id,
          language: post.node.language,
          featured_image: { ...post.node.featured_image?.data?.sizes },
          slug: post.node.slug,
          excerpt: post.node.excerpt,
          title: post.node.title,
          categories: post.node.categories?.data,
          author: {
            name: `${post.node.author?.data?.first_name} ${post.node.author?.data?.last_name}`,
            profile_picture: post.node.author?.data?.profile_picture?.data?.url,
          },
        };
      })
      .filter(({ language, categories }) => {
        // If categoryFilter is undefined, then no category filtering needed and it is All Posts page
        return categoryFilter
          ? categories?.some((category) => category?.name === categoryFilter) &&
              language === i18n.language
          : language === i18n.language;
      });
    setPostData(languageSpecificData as any);
  }, [data]);

  //Initialize the intersection observer API
  // useEffect(() => {
  //   let options = {
  //     root: null,
  //     rootMargin: '20px',
  //     threshold: 1.0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, options);
  //   if (loadRef.current) {
  //     observer.observe(loadRef.current);
  //   }
  // }, []);

  // Handle loading more articles
  // useEffect(() => {
  //   if (loadMore && hasMore) {
  //     const currentLength = list.length;
  //     const isMore = currentLength < allNews.length;
  //     const nextResults = isMore
  //       ? allNews.slice(currentLength, currentLength + 10)
  //       : [];
  //     setList([...list, ...nextResults]);
  //     setLoadMore(false);
  //   }
  // }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  // useEffect(() => {
  //   const isMore = list.length < allNews.length;
  //   setHasMore(isMore);
  // }, [list]); //eslint-disable-line

  return (
    <section className="section-3">
      <div className="container">
        <div className="post-feed">
          {postData.map(({ id, title, excerpt, featured_image, author, categories, slug }, idx) => {
            return (
              <Fragment key={id}>
                <Post
                  title={title}
                  excerpt={excerpt}
                  image={featured_image.regular!}
                  author={author}
                  slug={
                    // Use absolute blog path to simlify switching category slugs for blog posts
                    `${i18n.language === 'en' ? '' : `/${i18n.language}`}/blog/${slugify(
                      categories[0].name
                    )}/${slug}`
                  }
                  categories={categories}
                />
                {idx === 2 && <FlowPoster />}
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Posts;
