import React from 'react';
import cx from 'classnames';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

interface TabsSectionProps {
  activeTabIndex: number;
}

const TabsSection = ({ activeTabIndex = 1 }: TabsSectionProps) => {
  const { i18n, t } = useI18next('blog');

  const tabs = [
    {
      label: t('Tabs.all'),
      route: '/blog',
      index: 1,
    },
    {
      label: t('Tabs.news'),
      route: '/blog/news',
      index: 2,
    },
    {
      label: t('Tabs.money'),
      route: '/blog/money-methods',
      index: 3,
    },
    {
      label: t('Tabs.business'),
      route: '/blog/business',
      index: 4,
    },
    {
      label: t('Tabs.stories'),
      route: '/blog/stories',
      index: 5,
    },
  ];

  return (
    <section className="section-2">
      <div className="container">
        <div className="section-2__content">
          <div className="blog-nav">
            <div className="blog-nav__container">
              {tabs.map((tab) => {
                return (
                  <Link
                    className={cx('blog-nav__link', {
                      'blog-nav__link--active': activeTabIndex === tab.index,
                    })}
                    language={i18n.language}
                    to={tab.route}
                    key={tab.index}
                  >
                    {tab.label}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TabsSection;
